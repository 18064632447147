import React from "react";
import Layout from "@/components/layout";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import {Col, Nav, Row, Tab} from 'react-bootstrap';


import light1 from "../assets/img/ldpost1.jpeg";
import light2 from "../assets/img/light-heavy.png";
import heavy1 from "../assets/img/hdpost1.jpeg"
import heavy2 from "../assets/img/hdpost2.jpeg"
import newby from "../assets/img/newby-base-plate.jpeg"
import cement from "../assets/img/cement_sign.jpeg"


const SignBlanks = () => {
    return (
        <MenuContextProvider>
            <Layout PageTitle="Signposts">
                <HeaderOne/>
                <PageHeader title="Signposts" name="Signposts"/>
                <div className="sec-title text-center">
                    <p className={"m-5 p-5 "}>
                        Our high-quality signposts are incredibly durable, ranging in size, and built with your needs
                        in mind. You can’t go wrong with these high-quality options.
                    </p>
                </div>

                <div className="tab-wrapper m-5">
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-12">

                                <Tab.Container defaultActiveKey="light">
                                    <Row>
                                        <Col sm={3}>

                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item >
                                                    <Nav.Link eventKey="light">Light Duty</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="heavy">Heavy Duty</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="spring">Spring Loaded Post</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="cement">Cement Base</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="newby">Newby Base Plate</Nav.Link>
                                                </Nav.Item>

                                            </Nav>

                                        </Col>
                                        <Col sm={7}>
                                            <Tab.Content>

                                                <Tab.Pane eventKey="light">
                                                    <h5>Light Duty</h5>
                                                    <p>
                                                        Our Light Duty signposts are made of 14-gauge galvanized steel. These high-quality posts provide durability, rust resistance, and easy handling.

                                                        The bottom end of these signposts are tapered for easier installation and the top 2 feet are pre-punched for further convenience.

                                                        Light Duty signposts are available for immediate delivery in 48” 72” and 96” lengths.
                                                    </p>
                                                    <h6> Size Gauges:</h6>
                                                    <ul>
                                                        <li>48" - 14 Gge </li>
                                                        <li>72" - 14 Gge </li>
                                                        <li>96" - 14 Gge </li>
                                                    </ul>
                                                    <p>Mounting hardware is included with purchase. One unit contains all of the hardware needed to mount one sign.

                                                        If you know what you’d like to order, request a quote now. If you’d like to chat about your options or ask any questions, don’t hesitate to reach out. </p>
                                                    <img src={light1} alt=""/>
                                                    <img src={light2} alt=""/>

                                                </Tab.Pane>

                                                <Tab.Pane eventKey="heavy">

                                                    <h5>Heavy Duty</h5>
                                                    <p>
                                                        Our Heavy Duty signposts are built to Alberta Government Highway
                                                        Traffic specifications. These posts are available for immediate
                                                        delivery in  6’ 8’ 10’  and 12’ lengths. Each post has holes 1 inch
                                                        apart along the entire length.</p>
                                                    <h6> Available Lengths</h6>
                                                    <ul>
                                                        <li>6' - 1.75 lbs/ ft</li>
                                                        <li>8' - 1.75 lbs/ ft </li>
                                                        <li> 10' - 1.75 lbs/ ft </li>
                                                        <li>  12' - 1.75 lbs/ ft </li>
                                                    </ul>
                                                    <p>
                                                        Mounting hardware is included with purchase. One unit contains all of the hardware needed to mount one sign.

                                                        If you know what you’d like to order, request a quote now. If you’d like to chat about your options or ask any questions, don’t hesitate to reach out.
                                                    </p>
                                                    <img src={heavy1} alt=""/>
                                                    <img src={heavy2} alt=""/>





                                                </Tab.Pane>

                                                <Tab.Pane eventKey="spring">
                                                    <h5>Spring Loaded Post</h5>
                                                    <p>Our Spring Loaded Posts are convenient, durable, and incredibly high-quality. These specialty posts eliminate damage to parking lot surfaces, protect vehicles from unnecessary damage, handle any steel regulatory signs, and take just minutes to install with no special tools required.
                                                        <br/><br/>
                                                        If you know what you’d like to order, request a quote now. If you’d like to chat about your options or ask any questions, don’t hesitate to reach out.</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="cement">

                                                    <h5>Cement Base</h5>
                                                    <p>

                                                        If you are looking for a cement sign base, our bases are 120 lbs and 6”tall by 18” diameter. They fit both Light Duty and Heavy Duty Posts.
                                                        <br/><br/>
                                                        <img src={cement} alt=""/>
                                                    </p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="newby">
                                                    <h5>Newby Base Plate</h5>
                                                    <p>Introducing the Newby Base Plate. This product features:<br/><br/>

                                                        <ul>
                                                            <li>8" x 8" x .125 steel plate</li>
                                                            <li>6" x 1" x 1" tube welded to the plate</li>
                                                        </ul>
                                                        The Newby Base Plate will accept both Light Duty and Heavy Duty posts as well as our Spring Loaded Post.
                                                    </p>
                                                    <img src={newby} alt=""/>

                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Layout>
        </MenuContextProvider>
    );
};

export default SignBlanks;
